/**
 * @license
 *
 * Font Family: Switzer
 * Designed by: Jérémie Hornus
 * URL: https://www.fontshare.com/fonts/switzer
 * © 2023 Indian Type Foundry
 *
 * Font Styles:
 * Switzer Regular
 * Switzer Bold
 *
*/


@font-face {
    font-family: 'Switzer-Regular';
    src: url('../../public/fonts/Switzer-Regular.woff2') format('woff2'),
         url('../../public/fonts/Switzer-Regular.woff') format('woff'),
         url('../../public/fonts/Switzer-Regular.ttf') format('truetype');
         font-weight: 400;
         font-display: swap;
         font-style: normal;
  }
  
  
  @font-face {
    font-family: 'Switzer-Bold';
    src: url('../../public/fonts/Switzer-Bold.woff2') format('woff2'),
         url('../../public/fonts/Switzer-Bold.woff') format('woff'),
         url('../../public/fonts/Switzer-Bold.ttf') format('truetype');
         font-weight: 700;
         font-display: swap;
         font-style: normal;
  }
  
  